<template>
    <section class="l-welcome">
        <h1 class="l-welcome-ttl flex"><img src="/img/main_logo.png" alt="ReseMom 相談 online" class="l-logo"><span
                class="l-sub">について</span></h1>
        <div class="welcome-article">
            <p>リセマム相談onlineのページへようこそ！</p>
            <p>リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。</p>
            <p>「教育の相談がしたいけど、身近に聞ける人がいない…。」<br>
                「気軽に、すぐに相談できる場所が欲しい」など、<br>
                学齢期のお子さまをお持ちの保護者の方へ向けて、<br>
                「すぐに」「気軽に」「どこでも」教育相談を利用できます。 </p>
            <p>やり取りは全てオンライン上で行います。<br>
                時間や場所を気にせず、テキスト・通話・ビデオチャットなど環境に合わせた相談が可能です。</p>
            <p>教育相談をしてみたい！と思った方は、まずは<router-link to="/guide">利用ガイド</router-link>をご確認ください。</p>
        </div>

        <ul class="flex wrap l-btn-area">
            <li class="l-item-btn"><button data-v-9bbed40e="" class="btn-register btn-radius"
                    @click="_register">会員登録</button></li>
            <li class="l-item-btn"><button class="btn-apply-teacher-info btn-radius teacher"
                    @click="applyTeacher">講師に応募する</button></li>
        </ul>
        <div class="txt-center">
            <router-link to="/guide">ご利用ガイド（ユーザー向け）</router-link>
        </div>
        <div class="txt-center mt-3">
            <router-link to="/guide2">ご利用ガイド（講師向け）</router-link>
        </div>
        <RegisterModalSp :isVisible="this.isVisible" @cancel="actionCancel" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            isVisible: false
        }
    },
    created() {
        if(this.isMember) {
            this.getUserInfo();
        }   
    },
    computed: {
        isMember(){
            return this.user.info;
        },
        ...mapGetters({
            user: "member/getUser"
        }),
    },
    methods: {
        ...mapActions({
            getUserInfo: "member/getUserInfo",
        }),
        applyTeacher() {
            if(this.isMember) {  
                if (this.user.teacher
                    && this.user.teacher.allow_sell == 1
                    && this.user.stripe.is_account_verify == true) {
                    this.$router.push("/my-page/lesson");
                    return;
                } 
                this.$router.push("/apply-sell");
            } 
            this.isVisible = true;
        },
        actionCancel() {
            this.isVisible = false;
        },
        _register() {
            if(this.isMember) {
                window.$("#mms-edit-profile").click();
            }else {
                window.$("#mms-sign-up").click();
            }
        },
    },
}
</script>

<style></style>